import {isEditor} from '../../../../../../commons/selectors/environment'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {isMembersEnabled, isMembersVisible} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as CardPresentation} from './card'
import {CardOwnProps, CardStateProps} from './interfaces'

const mapRuntime = ({state, host: {dimensions}}: AppProps, {event}: CardOwnProps): CardStateProps => {
  return {
    showMembers: isMembersVisible(state, event),
    membersEnabled: isMembersEnabled(state),
    hasRibbon: getRibbonData(state, event).visible,
    editor: isEditor(state),
    expanded: isItemOpened(state, event.id),
    compId: state.component.id,
  }
}

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(CardPresentation)

export * from './interfaces'
